import React, { useState, useEffect } from 'react'
import './checkout.css'
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { MdDeleteOutline } from "react-icons/md";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import CircularProgress from '@mui/material/CircularProgress';
import { MdVerified } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import { TextField } from '@mui/material';
import axios from 'axios'
import { createAnOrder, resetState, createAbondend, sendOtp } from '../../features/user/userSlice'
import { getAllCoupons } from '../../features/coupon/couponSlice'
import toast from 'react-hot-toast'
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { IoCloseOutline } from "react-icons/io5";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
const Checkout = () => {
    const [open, setOpen] = React.useState(false);
    const otpState = useSelector((state) => state?.auth?.otp)
    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [success, setSuccess] = useState(false)
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [mobile, setMobile] = useState("")
    const [address, setAddress] = useState("")
    const [city, setCity] = useState("")
    const [state, setState] = useState("")
    const [pincode, setPincode] = useState("")
    const [verified, setVerified] = useState(false)
    const [cartItems, setCartItems] = useState([]);
    const address1 = JSON.parse(localStorage.getItem("address"))
    useEffect(() => {
        document.title = "Checkout";
        document.querySelector('meta[name="description"]').setAttribute('content', "Checkout");
      }, []);
    useEffect(() => {
        if (!firstname && !lastname && !email && !address && !phone && !mobile && !city && !state && !pincode) {
            setFirstname(address1?.firstname || "")
            setLastname(address1?.lastname || "")
            setEmail(address1?.email || "")
            setAddress(address1?.address || "")
            setPhone(address1?.phone || "")
            setMobile(address1?.mobile || "")
            setCity(address1?.city || "")
            setState(address1?.state || "")
            setPincode(address1?.pincode || "")
        }
    }, [address1, firstname, lastname, email, address, phone, mobile, city, state, pincode])

    const navigate = useNavigate()

    const addProductToOrderLocalStorage = (product) => {
        const existingOrder = JSON.parse(localStorage.getItem("orders")) || [];
        const updatedOrder = [...existingOrder, product];
        localStorage.setItem("orders", JSON.stringify(updatedOrder));
    };
    function normalizePhoneNumber(phoneNumber) {
        let cleanNumber = phoneNumber.replace(/\D/g, '');
        if (cleanNumber.startsWith('91') && cleanNumber.length > 10) {
            cleanNumber = cleanNumber.substring(2);
        } else if (cleanNumber.startsWith('0') && cleanNumber.length > 10) {
            cleanNumber = cleanNumber.substring(1);
        }
        return cleanNumber;
    }

    const [verify, setVerify] = useState("SEND OTP")
    const [otp, setOtp] = useState()
    const [noneOtp, setNoneotp] = useState("none")
    const [paySpin, setPaySpin] = useState(false)
    const [totalAmount, setTotalAmount] = useState(null)
    const [orderType, setOrderType] = useState("Prepaid")
    const [shippingCost, setShippingCost] = useState(0)
    const [cartProductState, setCartProductState] = useState([])
    const [coupon, setCoupon] = useState("")
    const couponState = useSelector((state) => state?.coupon?.coupon)
    const [payMethod, setPayMethod] = useState("payu")

    useEffect(() => {
        const cartFromStorage = JSON.parse(localStorage.getItem('cart')) || [];
        setCartItems(cartFromStorage);
    }, []);


    const removeFromCartAndUpdate = (productIdToRemove, colorToRemove, sizeToRemove) => {
        const updatedCartItems = cartItems.filter(item => {
            return !(item.productId === productIdToRemove && item?.color === colorToRemove && item.size === sizeToRemove);
        });
        localStorage.setItem('cart', JSON.stringify(updatedCartItems));
        setCartItems(updatedCartItems);
        toast.success("Removed");
    };

const applyCoupon = () => {
    couponState?.forEach((item) => {
        if (item?.name.toLowerCase() === coupon.toLowerCase() && item?.status === "active") {
            const applyDiscount = (discount) => {
                if (discount.includes("%")) {
                    const percent = parseFloat(discount) / 100;
                    setCouponAmount(percent * totalAmount);
                } else {
                    setCouponAmount(parseInt(discount));
                }
            };

            if (item?.discounttype === "freeShip") {
                setShippingCost(0);
            } else if (item?.discounttype === "buyX") {
                if (item?.minItem <= 10 && item?.minItem >= cartItems?.length) {
                    applyDiscount(item?.discount);
                } else {
                    toast.error("");
                }
            } else if (item?.discounttype === "order") {
                applyDiscount(item?.discount);
            }

            toast.success("Coupon Code Applied");
        }
        // else {
        //     toast.error("Invalid Coupon");
        // }
    });
};

    const codClick = () => {
        setShippingCost(200)
        setOrderType("COD")
        setCouponAmount(0)
        setPayMethod("cod")
        toast.error("Oops, you are missing top deals by selecting COD")
    }
    const payuClick = () => {
        setShippingCost(0)
        setOrderType("Prepaid")
        setCouponAmount((totalAmount) / 10)
        setPayMethod("payu")
    }
    const phonepeClick = () => {
        setShippingCost(0)
        setOrderType("Prepaid")
        setCouponAmount((totalAmount) / 10)
        setPayMethod("phonepe")
    }

    useEffect(() => {
        let sum = 0;
        for (let index = 0; index < cartItems?.length; index++) {
            sum = sum + (Number(cartItems[index]?.quantity) * cartItems[index]?.price)
            setTotalAmount(sum)
        }
    }, [cartItems])

    const [couponAmount, setCouponAmount] = useState()

    useEffect(() => {
        setCouponAmount(totalAmount / 10)
    }, [totalAmount])
    const finalAmount = shippingCost + totalAmount - couponAmount
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllCoupons())
    }, [])


    const completeOrder = () => {
        if (firstname === "" || lastname === "" || email === "" || phone === "" || address === "" || city === "" || state === "" || pincode === "") {
            toast.error("Please Fill All Information")
        }
        else if (verified === false) {
            toast.error("Phone Number Not Verified")
        }
        else {
            setPaySpin(true)
            localStorage.setItem("address", JSON.stringify({
                firstname: firstname,
                lastname: lastname,
                email: email,
                address: address,
                phone: normalizePhoneNumber(phone),
                mobile: mobile,
                city: city,
                state: state,
                pincode: pincode,
                isVarified: verified
            }))
            if (cartItems?.length >= 1) {
                setSuccess(true)
                setTimeout(() => {
                    checkOutHandler()
                }, 300)
            }
        }
    }

    useEffect(() => {
        let items = []
        for (let index = 0; index < cartItems?.length; index++) {
            items.push({ product: cartItems[index]?.product, quantity: cartItems[index].quantity, price: cartItems[index].price, color: cartItems[index].color, size: cartItems[index].size, sku: cartItems[index]?.product?.sku })
        }
        setCartProductState(items)
    }, [cartItems])

    const generateUniqueId = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const day = now.getDate().toString().padStart(2, '0');
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
        const milliseconds = now.getMilliseconds().toString().padStart(3, '0');
        const dateTimeString = `${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}`;
        const randomElement = Math.random().toString(36).substring(2, 10);
        const uniqueId = `${dateTimeString}-${randomElement}`;
        return uniqueId;
    };

    const [uniqueId, setUniqueId] = useState('');
    const [transactionId, setTransactionId] = useState(null)
    const [hash, setHash] = useState(null)
    useEffect(() => {
        const id = generateUniqueId();
        setUniqueId(id);
        setTransactionId(id)
    }, []);

    const getHash = () => {
        axios.post("https://voguemine2-kd7q.onrender.com/api/user/payu/hash", { firstname, email, finalAmount, phone, transactionId: uniqueId })
            .then(res => {
                setHash(res.data.hash)
                setTransactionId(res.data.transactionId)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const checkOutHandler = async (e) => {
        if (orderType === "COD") {
            const data = {
                orderCreationId: "COD", 
                razorpayPaymentId: "COD",
                razorpayOrderId: "COD", 
            };
            await dispatch(createAnOrder({ totalPrice: totalAmount, finalAmount: finalAmount, shippingCost: shippingCost, orderType: orderType, discount: couponAmount, orderItems: cartProductState, paymentInfo: data, shippingInfo: JSON.parse(localStorage.getItem("address")), tag: "Voguemine", isPartial: false }))
            addProductToOrderLocalStorage({ totalPrice: totalAmount, finalAmount: finalAmount, shippingCost: shippingCost, orderType: orderType, discount: couponAmount, orderItems: cartProductState, paymentInfo: data, shippingInfo: JSON.parse(localStorage.getItem("address")), tag: "Voguemine", isPartial: false })
            localStorage.clear("cart")
            setTimeout(() => {
                navigate("/profile")
            }, 1000);
            setPaySpin(false)
        }

        else {
            if (payMethod === "phonepe") {
                const data = {
                    orderCreationId: "Prepaid", // Set a placeholder value for order creation ID for COD orders
                    razorpayPaymentId: uniqueId, // Set a placeholder value for Razorpay payment ID for COD orders
                    razorpayOrderId: "Phonepe", // Set a placeholder value for Razorpay order ID for COD orders
                };
                localStorage.setItem("recentOrder", JSON.stringify({ totalPrice: totalAmount, finalAmount: finalAmount, shippingCost: shippingCost, orderType: orderType, discount: couponAmount, orderItems: cartProductState, paymentInfo: data, shippingInfo: JSON.parse(localStorage.getItem("address")), tag: "Voguemine", isPartial: false }));
                axios.post("https://voguemine2-kd7q.onrender.com/api/user/order/checkout", { amount: finalAmount, number: phone, merchantTransactionId: uniqueId })
                    .then(response => {
                        window.location.href = response.data
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
            if (payMethod === "payu") {
                getHash()
                handleClickOpen()
                const data = {
                    orderCreationId: "PayU",
                    razorpayPaymentId: transactionId,
                    razorpayOrderId: "PayU", 
                };
                localStorage.setItem("payUOrder", JSON.stringify({ totalPrice: totalAmount, finalAmount: finalAmount, shippingCost: shippingCost, orderType: orderType, discount: couponAmount, orderItems: cartProductState, paymentInfo: data, shippingInfo: JSON.parse(localStorage.getItem("address")), tag: "Voguemine", isPartial: false }));
            }}}

    useEffect(() => {
        localStorage.setItem("temp", JSON.stringify(
            {
                shippingInfo: {
                    firstname: firstname,
                    lastname: lastname,
                    email: email,
                    phone: normalizePhoneNumber(phone),
                    address: address,
                    city: city,
                    state: state,
                    pincode: pincode,
                    mobile: mobile,
                },
                tag: "Voguemine",
                isPartial: false,
                orderItems: cartProductState,
                totalPrice: totalAmount,
                shippingCost: shippingCost,
                orderType: orderType,
                discount: couponAmount,
                finalAmount: finalAmount,
                success: success
            }))
    }, [firstname, lastname, email, phone, mobile, address, city, state, pincode, cartProductState, success])

    const [isRead, setIsread] = useState(false)
    const initialTime = 60;

    const [timeLeft, setTimeLeft] = useState(initialTime);
    const [intervalId, setIntervalId] = useState(null);


    const sendOtps = async () => {
        if (phone?.length < 10) {
            toast.error("Please Fill Correct Number")
        }
        else {
            setVerify("Verify")
            setNoneotp("block")
            await dispatch(sendOtp(normalizePhoneNumber(phone)))
            setTimeLeft(initialTime);

            if (intervalId) clearInterval(intervalId);
            const id = setInterval(() => {
                setTimeLeft((prevTime) => {
                    if (prevTime <= 1) {
                        clearInterval(id);
                        return 0;
                    }
                    return prevTime - 1;
                });
            }, 1000);
            setIntervalId(id);
        } }

    // useEffect(() => {
    //     const otps = JSON.parse(localStorage.getItem("verifiedOtp"))
    //     setVerified(otps?.verified)
    // }, [])

    const verifyOtp = () => {
        if (otpState?.otps === parseInt(otp)) {
            localStorage?.setItem("verifiedOtp", JSON.stringify({
                verified: true
            }))
            setVerified(true)
            toast.success("VERIFIED")
            setIsread(true)
        }

        else {
            setVerified(false)
            localStorage?.setItem("verifiedOtp", JSON.stringify({
                verified: false
            }))
            setIsread(true)
            toast.error("Wrong OTP")
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setPaySpin(false)
    };


    useEffect(() => {
        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [intervalId]);

    const formatTime = () => {
        const minutes = Math.floor(timeLeft / 60);
        const seconds = timeLeft % 60;
        return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
    };
    const modifyCloudinaryUrl = (url) => {
        const urlParts = url?.split('/upload/');
        return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
    };


    useEffect(() => {
        if (phone?.length === 10 && firstname !== "") {
            if (cartItems?.length > 0) {
                dispatch(createAbondend({
                    shippingInfo: {
                        firstname: firstname,
                        lastname: lastname,
                        email: email,
                        phone: normalizePhoneNumber(phone),
                        address: address,
                        city: city,
                        state: state,
                        pincode: pincode,
                        mobile: mobile,
                    },
                    tag: "Voguemine",
                    isPartial: false,
                    orderItems: cartProductState,
                    totalPrice: totalAmount,
                    shippingCost: shippingCost,
                    orderType: orderType,
                    discount: couponAmount,
                    finalAmount: finalAmount,
                    success: success
                }));
            }
        }
    }, [phone])
    useEffect(() => {
        const canonicalUrl =`https://voguemine.com/checkout`;
        let canonicalLink = document.querySelector('link[rel="canonical"]');
    
        if (canonicalLink) {
          canonicalLink.setAttribute('href', canonicalUrl);
        } else {
          canonicalLink = document.createElement('link');
          canonicalLink.setAttribute('rel', 'canonical');
          canonicalLink.setAttribute('href', canonicalUrl);
          document.head.appendChild(canonicalLink);
        }
    }, []);
    return (
        <div className='margin-section checkout'>
            <div className="left-form">
                <p className='section-heading'>Info</p>
                <div className="name input">
                    <div>
                        <TextField
                            label="First Name*"
                            type="text"
                            name='firstname'
                            value={firstname} onChange={(e) => setFirstname(e.target.value)}
                        />

                    </div>
                    <div><TextField
                        label="Last Name*"
                        type="text"
                        name='lastname'
                        value={lastname} onChange={(e) => setLastname(e.target.value)}
                    /> </div>
                </div>
                <div className="mobile input">

<FormControl>
    <InputLabel htmlFor="standard-adornment-password">Phone*</InputLabel>

    <OutlinedInput

        style={{ width: '100%', display: 'grid', gridTemplateColumns: '11fr 1fr' }}
        type="number"
        name='phone'
        value={phone} onChange={(e) => setPhone(e.target.value)}
        readOnly={isRead}
        endAdornment={
            <InputAdornment position="end">

                <IconButton
                    style={{ display: verified === true ? "block" : "none" }}
                    aria-label="toggle password visibility"
                    edge="end"
                >
                    <MdVerified className='v-tag' />
                </IconButton>
            </InputAdornment>
        }
    />
</FormControl>
<div className='phone-veri' style={{ display: verified === true ? "none" : "flex" }}>
    <input type="number" value={otp} placeholder='Enter OTP' onChange={(e) => setOtp(e.target.value)} style={{ display: noneOtp }} maxLength={6} />
    {
        timeLeft > 0 ? <button onClick={verify === "SEND OTP" ? sendOtps : verifyOtp} >{verify}</button>
            :
            <button onClick={sendOtps} >SEND AGAIN</button>

    }
    {
        timeLeft > 0 ? <p style={{ display: verify === "SEND OTP" ? "none" : "block" }}>Try Again in: {formatTime()}</p> :
            ""}

</div>

</div>
                <p className="section-heading">Delivery</p>
                <div className="email input">
                    <TextField
                        label="Email*"
                        type="email"
                        name='email'
                        value={email} onChange={(e) => setEmail(e.target.value)}
                    />

                </div>
                
                <div className="address input">
                    <TextField
                        label="Address*"
                        type="text"
                        name='address'
                        value={address} onChange={(e) => setAddress(e.target.value)}
                    />

                </div>
                <div className="city input">
                    <div>
                        <TextField
                            label="City*"
                            type="text"
                            name='city'
                            value={city} onChange={(e) => setCity(e.target.value)}
                        />

                    </div>
                    <div><select name="state" placeholder="State" value={state} onChange={(e) => setState(e.target.value)}>
                        <option value="">State</option>
                        <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                        <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                        <option value="Assam">Assam</option>
                        <option value="Bihar">Bihar</option>
                        <option value="Chandigarh">Chandigarh</option>
                        <option value="Chhattisgarh">Chhattisgarh</option>
                        <option value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
                        <option value="Daman and Diu">Daman and Diu</option>
                        <option value="Delhi">Delhi</option>
                        <option value="Goa">Goa</option>
                        <option value="Gujarat">Gujarat</option>
                        <option value="Haryana">Haryana</option>
                        <option value="Himachal Pradesh">Himachal Pradesh</option>
                        <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                        <option value="Jharkhand">Jharkhand</option>
                        <option value="Karnataka">Karnataka</option>
                        <option value="Kerala">Kerala</option>
                        <option value="Lakshadweep">Lakshadweep</option>
                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                        <option value="Maharashtra">Maharashtra</option>
                        <option value="Manipur">Manipur</option>
                        <option value="Meghalaya">Meghalaya</option>
                        <option value="Mizoram">Mizoram</option>
                        <option value="Nagaland">Nagaland</option>
                        <option value="Orissa">Orissa</option>
                        <option value="Pondicherry">Pondicherry</option>
                        <option value="Punjab">Punjab</option>
                        <option value="Rajasthan">Rajasthan</option>
                        <option value="Sikkim">Sikkim</option>
                        <option value="Tamil Nadu">Tamil Nadu</option>
                        <option value="Telangana">Telangana</option>
                        <option value="Tripura">Tripura</option>
                        <option value="Uttaranchal">Uttaranchal</option>
                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                        <option value="West Bengal">West Bengal</option>
                    </select>

                    </div>
                    <div><TextField
                        label="Pin Code*"
                        type="number"
                        name='pincode'
                        value={pincode} onChange={(e) => setPincode(e.target.value)}
                    /></div>
                </div>
               
                <div className="alter-mobile input">
                    <TextField
                        label="Alternative Phone (Optional)"
                        type="number"
                        name='mobile'
                        value={mobile} onChange={(e) => setMobile(e.target.value)}
                    />

                </div>
                <div className="payment">
                    <p className="section-heading">Payment</p>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="payu"
                        name="radio-buttons-group"
                    >
                        <div className='razorpay'>
                            <FormControlLabel value="payu" control={<Radio />} label="PayU Payment Gateway" onClick={payuClick} />

                        </div>
                        <div className="razorpay">
                            <FormControlLabel value="razorpay" control={<Radio />} label="PhonePe (UPI, Cards, NetBanking)" disabled={false} onClick={phonepeClick} />
                        </div>

                        <div className='razorpay'>
                            <FormControlLabel value="cod" control={<Radio />} label="Cash on Delivery" onClick={codClick} />

                        </div>
                    </RadioGroup>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                    {
                        paySpin === true ? <CircularProgress /> :
                            <button className='pay' disabled={paySpin} onClick={completeOrder}>Place Order</button>
                    }
                    <BootstrapDialog
                        onClose={handleClose}
                        aria-labelledby="customized-dialog-title"
                        open={open}
                    >
                        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                            Your Payment Details
                        </DialogTitle>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <IoCloseOutline />
                        </IconButton>
                        <DialogContent dividers>
                            <div className="pay-details">
                                <p><span>Name:</span><span>{firstname}</span></p>
                                <p><span>Email:</span><span>{email}</span></p>
                                <p><span>Phone:</span><span>{phone}</span></p>
                                <p><span>Amount:</span><span>{finalAmount}</span></p>
                            </div>
                            <div className="paynow">
                                <form action='https://secure.payu.in/_payment' method='post'>
                                    <input type="hidden" name="key" value="UMp7He" />
                                    <input type="hidden" name="txnid" value={transactionId} />
                                    <input type="hidden" name="productinfo" value={phone} />
                                    <input type="hidden" name="amount" value={finalAmount} />
                                    <input type="hidden" name="email" value={email} />
                                    <input type="hidden" name="firstname" value={firstname} />
                                    <input type="hidden" name="lastname" value={lastname} />
                                    <input type="hidden" name="surl" value="https://voguemine2-kd7q.onrender.com/api/user/payu/success" />
                                    <input type="hidden" name="furl" value="https://voguemine2-kd7q.onrender.com/api/user/payu/failed" />
                                    <input type="hidden" name="phone" value={phone} />
                                    <input type="hidden" name="udf1" value={'details1'} />
                                    <input type="hidden" name="udf2" value={'details2'} />
                                    <input type="hidden" name="udf3" value={'details3'} />
                                    <input type="hidden" name="udf4" value={'details4'} />
                                    <input type="hidden" name="udf5" value={'details5'} />
                                    <input type="hidden" name="hash" value={hash} />
                                    <div className="sbmt">
                                        <button type='submit'>Pay Now</button>
                                    </div>
                                </form>
                            </div>
                        </DialogContent>
                    </BootstrapDialog>

                </div>
            </div>
            <div className="right-form">
                {
                    cartItems?.map((item, index) => {
                        return (
                            <div className="prdt" key={index}>
                                <div className="detail">
                                    <img src={modifyCloudinaryUrl(item?.product?.images[0]?.url)} alt="" />
                                    <div><p className="p-name">{item?.product?.title}</p>
                                        <p className="size"><span>{item?.size}</span><span>/</span><span>{item?.color}</span></p></div>
                                </div>
                                <p className="p-price">&#8377;{(item?.price) * (item?.quantity)}</p>
                                <p className="delete" onClick={() => removeFromCartAndUpdate(item.productId, item.color, item.size)}><MdDeleteOutline className='delete-icon' /></p>
                            </div>
                        )
                    })
                }
                <div className="coupon">
                    <input
                        id="coupon"
                        label="Coupon Code"
                        type="text"
                        value={coupon}
                        onChange={(e) => setCoupon(e.target.value)}
                    />
                    <button onClick={applyCoupon}>APPLY</button>
                </div>
                <div className="total">
                    <ul>
                        <li>Subtotal</li>
                        <li>Shipping</li>
                        <li>Discount</li>
                        <li style={{ fontSize: '20px', fontWeight: 600 }}>Total</li>
                    </ul>
                    <ul>
                        <li>&#8377; {totalAmount}</li>
                        <li>&#8377; {shippingCost !== 0 ? shippingCost : `${shippingCost}(Free)`}</li>
                        <li>&#8377; -{couponAmount}</li>
                        <li style={{ fontSize: '20px', fontWeight: 600 }}>&#8377; {finalAmount}</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Checkout
