import React ,{useEffect} from 'react'
import './error.css'
import { useNavigate } from 'react-router-dom'
import errImg from '../../images/404.png'

const Error = () => {
  const navigate=useNavigate()
  useEffect(() => {
    document.title = "404 Page Not Found";
    document.querySelector('meta[name="description"]').setAttribute('content', "404 Page Not Found");
  }, []);
  return (
    <div className='error-page'>
      <div className="error-msg">
      <img src={errImg} alt="" />
      <h1 style={{opacity:0,margin:0}}>404</h1>

        <h1>PAGE NOT FOUND</h1>
        <p>The page you are trying to access does not exist.</p>
        <button onClick={()=>navigate(-1)}>GO BACK</button>
      </div>
    </div>
  )
}

export default Error
