import React,{useEffect,useState} from 'react'
import './category.css'
import { Link } from 'react-router-dom'
import {useSelector } from 'react-redux'
import loadingImg from '../../images/loading.gif'

const Kids = () => {
    const collectionState=useSelector((state)=>state?.collection?.collection)
    const modifyCloudinaryUrl = (url) => {
        const urlParts = url?.split('/upload/');
        return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
      };
      const [loading,setLoading]=useState(true)
      useEffect(()=>{
setTimeout(()=>{
    setLoading(false)
},500)
      },[collectionState])
      useEffect(() => {
        const canonicalUrl =`https://voguemine.com/kids`;
        let canonicalLink = document.querySelector('link[rel="canonical"]');
    
        if (canonicalLink) {
          canonicalLink.setAttribute('href', canonicalUrl);
        } else {
          canonicalLink = document.createElement('link');
          canonicalLink.setAttribute('rel', 'canonical');
          canonicalLink.setAttribute('href', canonicalUrl);
          document.head.appendChild(canonicalLink);
        }
    }, []);
    useEffect(() => {
      document.title = "Kids' Fashion |  Premium & Luxury Clothing for Boys and Girls - Voguemine";
      document.querySelector('meta[name="description"]').setAttribute('content', "Explore premium and luxury clothing for kids at Voguemine. Shop high-quality, stylish outfits for boys and girls, perfect for any occasion. Discover the best in kids' fashion today!");
  }, []);
  return (
    <div className='categoryPage'>
        <div className="loading" style={{ display: loading === true ? "block" : "none" }}>
        <div className="loader">
          <img src={loadingImg} alt="" />
          <p>Loading . . .</p>
        </div>
      </div>
            <p className="category-heading">Kid's Collection</p>

    <div className="collections">
    <Link to={`/collections/${collectionState[16]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[16]?.images[2]?.url)} alt="" />
      </div></Link>
      <Link to={`/collections/${collectionState[13]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[13]?.images[2]?.url) } alt="" />
      </div></Link>
      <Link to={`/collections/${collectionState[36]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[36]?.images[2]?.url)} alt="" />
      </div></Link>
      
      <Link to={`/collections/${collectionState[24]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[24]?.images[2]?.url)} alt="" />
      </div></Link>
      <Link to={`/collections/${collectionState[34]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[34]?.images[2]?.url)} alt="" />
      </div></Link>
      <Link to={`/collections/${collectionState[21]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[21]?.images[2]?.url)} alt="" />
      </div></Link>
<Link to={`/collections/${collectionState[29]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[29]?.images[2]?.url)} alt="" />
      </div></Link>

    </div>
  </div>
  )
}

export default Kids
