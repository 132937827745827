import React,{useEffect} from 'react'
import './category.css'
import {useSelector } from 'react-redux'

const About = () => {
  const bannerState=useSelector((state)=>state?.banner?.banner)
  const modifyCloudinaryUrl = (url) => {
    const urlParts = url?.split('/upload/');
    return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
  };
  useEffect(() => {
    const canonicalUrl =`https://voguemine.com/about`;
    let canonicalLink = document.querySelector('link[rel="canonical"]');

    if (canonicalLink) {
      canonicalLink.setAttribute('href', canonicalUrl);
    } else {
      canonicalLink = document.createElement('link');
      canonicalLink.setAttribute('rel', 'canonical');
      canonicalLink.setAttribute('href', canonicalUrl);
      document.head.appendChild(canonicalLink);
    }
}, []);
useEffect(() => {
  document.title = "About Us - Voguemine";
  document.querySelector('meta[name="description"]').setAttribute('content', "About Us - Voguemine");
}, []);
  return (
    <div className='categoryPage'>
      <div className="about">
        <div className="left-about">
            <img src={modifyCloudinaryUrl(bannerState[37]?.images[0]?.url)} alt={bannerState[37]?.alt} />
            
        </div>
        <div className="right-about">
            <h1>ABOUT VOGUEMINE</h1>
            <p>At Voguemine, we understand, style is not just about what you wear, it's about how you feel. That's why we go above and beyond to provide an exceptional shopping experience, with attentive customer service and a user-friendly online platform that makes it easy to find the perfect addition of collection to your luxurious wardrobe.



</p>
<p>Our team of fashion experts has carefully handpicked a stunning collection of authentic store articles of luxury high-end designer apparel, accessories, and footwear. Each piece has been carefully inspected to ensure that it meets our attentive standards of quality and authenticity.</p>

        </div>
      </div>

<div className="c-banner" style={{marginBottom:'50px'}}>
  <img src={modifyCloudinaryUrl(bannerState[34]?.images[0]?.url) || "https://res.cloudinary.com/keshvendra/image/upload/v1713514549/00005_qdvxte.jpg"} alt={bannerState[34]?.alt} style={{width:'100%'}}/>
</div>

    </div>
  )
}

export default About
