import React, { useEffect,useState } from 'react'
import {Link} from 'react-router-dom'
import { IoCloseOutline } from "react-icons/io5";
import toast from 'react-hot-toast'
import { PiHandHeartThin } from "react-icons/pi";
import '../App.css'
const Wishlist = () => {
const [wishlist,setWishlist]=useState([])
    useEffect(() => {
      const cartFromStorage = JSON.parse(localStorage.getItem('wishlist')) || [];
      setWishlist(cartFromStorage);
  }, []);
  useEffect(() => {
    const canonicalUrl =`https://voguemine.com/wishlist`;
    let canonicalLink = document.querySelector('link[rel="canonical"]');

    if (canonicalLink) {
      canonicalLink.setAttribute('href', canonicalUrl);
    } else {
      canonicalLink = document.createElement('link');
      canonicalLink.setAttribute('rel', 'canonical');
      canonicalLink.setAttribute('href', canonicalUrl);
      document.head.appendChild(canonicalLink);
    }
}, []);
useEffect(() => {
  document.title = "Wishlist - Voguemine";
  document.querySelector('meta[name="description"]').setAttribute('content', "Wishlist - Voguemine");
}, []);

    const removeFromWishlist=(id)=>{
      const cardDetails = JSON.parse(localStorage.getItem('wishlist')) || [];
      const updatedWishlistDetails = cardDetails.filter(item => item.productId !== id);
        localStorage.setItem('wishlist', JSON.stringify(updatedWishlistDetails));
        setWishlist(updatedWishlistDetails)
      setTimeout(()=>{
       toast.success("Removed")
      },300)
    }
    const modifyCloudinaryUrl = (url) => {
      const urlParts = url?.split('/upload/');
      return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
    };
  return (
    <div className='mywish'>
        <p className="wish-head">My Wishlist <PiHandHeartThin className='wish-icon'/></p>
        {
            wishlist.length===0?
            <div className='no-data'>
<img src="https://i.pinimg.com/originals/f6/e4/64/f6e464230662e7fa4c6a4afb92631aed.png" alt="" />
<button>ADD SOMETHING</button>
              </div>
              :
              <div className="products-listing">
              <div className="product-list ">
      
      {
        wishlist?.map((item,index)=>{
          return(
            <div className="product-card" key={index}>
            <Link to={`/products/${item?.product?.handle}`}>
            <div className="product-img">
              <img src={modifyCloudinaryUrl(item?.product?.images[0]?.url)} alt="" className="product-img1"/>
              {
                item?.product?.images[2]?.url!==""?<img src={item?.product?.images[1]?.url} alt="" className="product-img2"/>:<img src=
                "" alt="" className="product-img2"/>
              }
            </div>
            </Link>
            <p className="wish-icon" onClick={()=>{removeFromWishlist(item?.product?._id)}}><IoCloseOutline className="cart-icon"/></p>
            <div className="product-content">
              <Link to={`/products/${item?.product?.handle}`}>
              <p className="title">{item?.product?.title}</p>
              </Link>
      <div className="wish">
      <div className='wish-price'>
      <p className="sale-price">&#8377;{item?.product?.price*2}</p>
      <p className="price">&#8377;{item?.product?.price}</p>
      </div>
      </div>
      
            </div>
          </div>
          )
        })
      }
               
      
              </div>
            </div>
          }
           
    </div>
  )
}

export default Wishlist
