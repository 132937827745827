import React,{useEffect,useState} from 'react'
import './blog.css'
import { useDispatch, useSelector } from 'react-redux'
import {getABlog } from '../../features/blogs/blogSlice';
import {useParams } from 'react-router-dom';
import loadingImg from '../../images/loading.gif'

const SingleBlog = () => {
  const { handle } = useParams()
    const singleBlogState=useSelector((state)=>state?.blog?.singleBlog)
    const dispatch=useDispatch();
    useEffect(()=>{
      dispatch(getABlog(handle))
    },[handle])
    useEffect(() => {
      if (singleBlogState?.metaTitle!=="" || singleBlogState?.metaTitle!==undefined) {
          document.title = singleBlogState?.metaTitle;
      }
      else{
        document.title =`${singleBlogState?.title}`;
      }
  
  }, [singleBlogState?.metaTitle]);
  useEffect(() => {
    if (singleBlogState?.metaDesc!=="" || singleBlogState?.metaDesc!==undefined) {
        document.querySelector('meta[name="description"]').setAttribute('content',singleBlogState?.metaDesc);
    }
    else{
      document.querySelector('meta[name="description"]').setAttribute('content',singleBlogState?.description);
    }
  
  }, [singleBlogState?.metaDesc]);  
  const [loading,setLoading]=useState(true)
  useEffect(()=>{
setTimeout(()=>{
setLoading(false)
},1000)
  },[singleBlogState])

  useEffect(() => {
    const canonicalUrl =`https://voguemine.com/blogs/news/${handle}`;
    let canonicalLink = document.querySelector('link[rel="canonical"]');

    if (canonicalLink) {
      canonicalLink.setAttribute('href', canonicalUrl);
    } else {
      canonicalLink = document.createElement('link');
      canonicalLink.setAttribute('rel', 'canonical');
      canonicalLink.setAttribute('href', canonicalUrl);
      document.head.appendChild(canonicalLink);
    }
}, [handle]);


  return (
    <div className='margin-section single-blog' style={{marginTop:'100px'}}>
      <div className="loading" style={{ display: loading === true ? "block" : "none" }}>
        <div className="loader">
          <img src={loadingImg} alt="" />
          <p>Loading . . .</p>
        </div>
      </div>
      <p className="section-heading">{singleBlogState?.title}</p>
      <div className="blog">
        <p className="desc" dangerouslySetInnerHTML={{ __html: singleBlogState?.description }}></p>
      </div>
    </div>
  )
}

export default SingleBlog
