import React ,{useEffect,useState} from 'react'
import './blog.css'
import { useDispatch, useSelector } from 'react-redux'
import {getAllBlogs } from '../../features/blogs/blogSlice';
import { Link } from 'react-router-dom';
import loadingImg from '../../images/loading.gif'

const Blog = () => {
  const blogState=useSelector((state)=>state?.blog?.blog)
    const dispatch=useDispatch();
    useEffect(()=>{
        getBlogs()
    },[])
    const getBlogs=()=>{
        dispatch(getAllBlogs())
    }
    const blogs=blogState? blogState :[]
    const modifyCloudinaryUrl = (url) => {
      const urlParts = url?.split('/upload/');
      return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
    };
    const [loading,setLoading]=useState(true)
    useEffect(()=>{
setTimeout(()=>{
  setLoading(false)
},1000)
    },[blogState])

    useEffect(() => {
      const canonicalUrl =`https://voguemine.com/blogs`;
      let canonicalLink = document.querySelector('link[rel="canonical"]');

      if (canonicalLink) {
        canonicalLink.setAttribute('href', canonicalUrl);
      } else {
        canonicalLink = document.createElement('link');
        canonicalLink.setAttribute('rel', 'canonical');
        canonicalLink.setAttribute('href', canonicalUrl);
        document.head.appendChild(canonicalLink);
      }
  }, []);

  useEffect(() => {
    document.title = "Voguemine Blog | Fashion Trends, Style Tips & Wardrobe Inspiration";
    document.querySelector('meta[name="description"]').setAttribute('content', "Explore the latest trends, fashion tips, and style guides on Voguemine. Stay updated with expert insights, reviews, and more to elevate your wardrobe and keep your fashion game strong with our daily blogs.");
}, []);

  return (
    <div className='blog-sec'>
      <div className="loading" style={{ display: loading === true ? "block" : "none" }}>
        <div className="loader">
          <img src={loadingImg} alt="" />
          <p>Loading . . .</p>
        </div>
      </div>
      <p className="section-heading">Our Blogs</p>
      <div className="blogs">
        {
         blogs?.slice()?.reverse()?.map((item,index)=>{
            return(
              <Link to={`/blogs/news/${item?.handle}`} className='blog-card'>

              <div className="blog" key={index}>
                <img src={modifyCloudinaryUrl(item?.images[0]?.url)} alt={item?.title} />
                <p className='title'> {item?.title}</p>
        <p className='desc' variant="body2" color="text.secondary" dangerouslySetInnerHTML={{ __html: item?.description }}>
        </p>
       <button size="small">Read More</button>
                </div>
                </Link>

                
            )
          })
        }
        


      </div>
      
    </div>

  )
}

export default Blog
