import React from 'react'
import './footer.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaInstagramSquare } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import {Link} from 'react-router-dom'
import { FaWhatsapp } from "react-icons/fa";
import logo from '../../images/vlogo.png'
const Footer = () => {
  return (
    <div className='footer'>
        <Container>
      <Row>
        <Col className=''>
        <h2>Collection</h2>
        <ul>
            <li><Link to="/men">Men</Link></li>
            <li><Link to="/women">Women</Link></li>
            <li><Link to="/kids">Kids</Link></li>
            <li><Link to="/accessories">Accessories</Link></li>
        </ul>
        </Col>
        <Col className=''>
        <h2>Useful Links</h2>
        <ul>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/pages/contact">Contact Us</Link></li>
            <li><Link to="/blogs">Blogs</Link></li>
        </ul>
        </Col>
        <Col className=''>
        <h2>Policies</h2>
        <ul>
            <li><Link to="/pages/shipping-policy">Shipping Policy</Link></li>
            <li><Link to="/pages/refund-and-return-policy">Exchange Policy</Link></li>
            <li><Link to="/pages/terms-of-service">Terms of Service</Link></li>
            <li><Link to="/pages/privacy-policy">Privacy Policy</Link></li>

        </ul>
        </Col>
        <Col className=''>
        <h2>Contact Information</h2>
        <ul>
            <li>Email: <Link to="mailto:customersupport@voguemine.com">customersupport@voguemine.com</Link></li>
            <li>Address: Noida, U.P</li>
            <ul>
                <li className='social'><Link to="https://www.instagram.com/voguemine_fashion/"><FaInstagramSquare/></Link></li>
                <li className='social'><Link to="https://www.facebook.com/vogueminefashion"><FaFacebookSquare/></Link></li>
            </ul>
        </ul>
        </Col>
      </Row>
    </Container>

    <hr style={{color:'white'}}/>
    <p className='copyright'>&copy; 2024, Voguemine</p>
    <Link to="https://wa.me/+919899202079?text=Hello%20there!" className="arrow-top1"><FaWhatsapp className='ico'/></Link>
    </div>
  )
}

export default Footer
