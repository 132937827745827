import React,{useEffect,useState} from 'react'
import './category.css'
import { Link } from 'react-router-dom'
import {useSelector } from 'react-redux'
import loadingImg from '../../images/loading.gif'

const Accessories = () => {
  const collectionState=useSelector((state)=>state?.collection?.collection)
  const modifyCloudinaryUrl = (url) => {
    const urlParts = url?.split('/upload/');
    return urlParts && `${urlParts[0]}/upload/c_limit,h_1000,f_auto,q_auto/${urlParts[1]}`;
  };
  const [loading,setLoading]=useState(true)
  useEffect(()=>{
setTimeout(()=>{
setLoading(false)
},500)
  },[collectionState])

  useEffect(() => {
    const canonicalUrl =`https://voguemine.com/accessories`;
    let canonicalLink = document.querySelector('link[rel="canonical"]');

    if (canonicalLink) {
      canonicalLink.setAttribute('href', canonicalUrl);
    } else {
      canonicalLink = document.createElement('link');
      canonicalLink.setAttribute('rel', 'canonical');
      canonicalLink.setAttribute('href', canonicalUrl);
      document.head.appendChild(canonicalLink);
    }
}, []);
useEffect(() => {
  document.title = "Men's Accessories | Premium Belts, Sunglasses, Caps & More - Voguemine";
  document.querySelector('meta[name="description"]').setAttribute('content', "Discover premium men's accessories at Voguemine. Shop stylish belts, sunglasses, caps, and more to enhance your look. Find high-quality, fashionable pieces to complete your ensemble. Shop now");
}, []);
  return (
    <div className='categoryPage'>
        <div className="loading" style={{ display: loading === true ? "block" : "none" }}>
        <div className="loader">
          <img src={loadingImg} alt="" />
          <p>Loading . . .</p>
        </div>
      </div>
                <p className="category-heading">Accessories Collection</p>


    <div className="collections">
    
    <Link to={`/collections/${collectionState[49]?.handle}`}>
      <div className="collection-card">
          <img src={collectionState && modifyCloudinaryUrl(collectionState[49]?.images[2]?.url)} alt="" />
      </div>
      </Link>
      <Link to={`/collections/${collectionState[14]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[14]?.images[2]?.url)} alt="" />
      </div>
      </Link>
      <Link to={`/collections/${collectionState[50]?.handle}`}>
      <div className="collection-card">
          <img src={collectionState && modifyCloudinaryUrl(collectionState[50]?.images[2]?.url)} alt="" />
      </div>
      </Link>
      <Link to={`/collections/${collectionState[51]?.handle}`}>
      <div className="collection-card">
          <img src={collectionState && modifyCloudinaryUrl(collectionState[51]?.images[2]?.url)} alt="" />
      </div>
      </Link>
      <Link to={`/collections/${collectionState[25]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[25]?.images[2]?.url)} alt="" />
      </div></Link><Link to={`/collections/${collectionState[15]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[15]?.images[2]?.url)} alt="" />
      </div></Link><Link to={`/collections/${collectionState[46]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[46]?.images[2]?.url)} alt="" />
      </div></Link><Link to={`/collections/${collectionState[45]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[45]?.images[2]?.url)} alt="" />
      </div></Link><Link to={`/collections/${collectionState[44]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[44]?.images[2]?.url)} alt="" />
      </div></Link><Link to={`/collections/${collectionState[47]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[47]?.images[2]?.url)} alt="" />
      </div></Link><Link to={`/collections/${collectionState[48]?.handle}`}>
      <div className="collection-card">
          <img src={modifyCloudinaryUrl(collectionState[48]?.images[2]?.url)} alt="" />
      </div>
      </Link>
    </div>
  </div>
  )
}

export default Accessories
